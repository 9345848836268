/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~chart.js/dist/Chart.min.css";

@font-face {
  font-family: SpiceIcons;
  src: url("assets/font/SpiceIcons.otf") format("opentype");
}

.spice-icon {
  font-family: "SpiceIcons", serif;
  font-size: 48px;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  font-family: "Muli", sans-serif;
}

.no-outline {
  outline: none !important;
}

.inline {
  display: inline !important;
}

.mat-tooltip {
  font-size: 15px;
  padding: 10px;
}

/* Hide the arrows on number input fields: */
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.full-height {
  height: 100%;
  background-color: rgba(235, 235, 235, 0.3);
}

.content-container {
  margin-right: auto;
  margin-left: auto;
}

.content-header {
  color: white;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 8px;
  h3 {
    margin: 0;
  }
}

.btn {
  min-width: 120px;
  border-radius: 20px;
}

.background-img {
  background: url("assets/main-back_v2.jpg") no-repeat fixed center;
  background-color: #f5f5f5;
  background-position: top right;
  min-height: 100%;
}

.background-img-landing {
  background-image: url("assets/main-back_v2.jpg");
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  background-position: top right;
  max-height: 220px;
}

.background-colored {
  background-color: #f5f5f5;
  min-height: 100%;
}

.banner {
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(1, 203, 186, 1) 0%,
    rgba(0, 210, 146, 1) 100%
  );
  color: white;
  opacity: 0.9;
  height: 220px;
  margin-right: 0px;
}

.below-banner {
  min-height: 100%;
  margin-right: 0px;
}

.below-banner-guest {
  min-height: 200% !important;
}

.main-section {
  padding-left: 11px !important;
  padding-right: 0px;
  height: 100%;
}

.header-spice {
  margin-bottom: 20px;
  margin-top: 20px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:focus,
.btn-secondary:not([disabled]):hover,
.btn-primary > em,
.btn-primary:hover > em,
.btn-primary:not(:disabled):not(.disabled):active > em,
.show > .btn-primary.dropdown-toggle,
.btn-primary:focus > em,
.btn-secondary:not([disabled]):hover > em {
  background: rgb(0, 210, 146);
  background: linear-gradient(
    90deg,
    rgba(0, 210, 146, 1) 0%,
    rgba(1, 203, 186, 1) 100%
  );
  color: white !important;
  border-color: rgba(1, 203, 186, 1) !important;
}

.btn-primary:focus {
  box-shadow: none !important;
}

.btn:focus {
  box-shadow: none !important;
}

.btn-secondary,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle,
.btn-secondary:focus {
  color: rgba(1, 203, 186, 1);
  border-color: rgba(1, 203, 186, 1);
  background-color: transparent;
}

.btn-cancel {
  color: gray;
  border-color: gray;
  background-color: transparent;
}

.btn-primary:disabled,
.btn-secondary:disabled,
.btn-cancel:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

button:focus {
  outline: 0;
}

/* Dropdown */

.dropdown-item .fas {
  padding-right: 9px;
}

.dropdown-item:not(.disabled) .fas {
  color: rgb(1, 203, 186);
}

.dropdown-item.disabled {
  cursor: not-allowed;
  background-color: #ededed;
}

.dropdown-icon-only .dropdown-toggle {
  min-width: auto;
  border: none;
  color: rgb(1, 203, 186);
}

.dropdown-item:not(.disabled):focus,
.dropdown-item:not(.disabled):hover {
  color: rgb(1, 203, 186);
  background-color: #f3f3f3;
}

.dropdown-icon-only .dropdown-toggle:focus,
.dropdown-icon-only .dropdown-toggle:hover {
  background-color: transparent;
}

.dropdown-icon-only .dropdown-toggle:after {
  display: none;
}

/* Badge */

.badge-primary {
  background-color: rgb(1, 203, 186);
}

.badge-secondary {
  background-color: #af9235;
}

.main-actions-container {
  background-color: white;
  border-radius: 8px;
  text-align: center;
  display: block;
  padding: 20px;
  margin-top: 5px;
}

.actions em,
.actions em:hover,
td > em:hover {
  background-color: transparent;
  color: rgba(1, 203, 186, 1);
  cursor: pointer;
}

.actions button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.actions button:disabled > em {
  color: #fff !important;
  background-color: #6c757d !important;
  cursor: default;
}

.actions button:disabled > .copy-scenario {
  color: #6c757d !important;
  cursor: not-allowed;
  background-color: transparent !important;
}

.btn > em {
  margin-right: 10px;
}

.actions > em[class~="isReference"]:hover,
td > em[class~="isReference"]:hover {
  color: #af9235 !important;
  background-color: white;
  cursor: default;
}

td > em {
  padding: 6px;
  background-color: transparent;
  color: rgba(1, 203, 186, 1);
  cursor: pointer;
}

/* Tooltip above disabled buttons */
.custom-tooltip {
  background-color: #6c757d;
  z-index: 1100;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1100 !important;
}

.modal-open .modal .custom-tooltip {
  z-index: 1100;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

.box-title > em {
  margin-left: 10px;
  color: rgba(1, 203, 186, 1);
  cursor: pointer;
  vertical-align: middle;
}

.fa-question-circle {
  color: #af9235;
  font-size: 19px;
  vertical-align: middle;
  cursor: pointer;
}

.spice-title-baskerville {
  font-family: "Libre Baskerville", serif;
  font-style: normal;
  font-weight: 200;
}

.spice-text-helvetica {
  font-family: "Helvetica Neue", serif;
}

th,
td {
  text-align: left;
}

td[class="icons"] {
  text-align: right;
}

td[class="icons"] > em {
  margin-right: 3px;
}

td[class="icons"] > em:last-child {
  margin-right: 0px;
}

td > em,
td > span > em {
  cursor: pointer;
  color: rgba(1, 203, 186, 1);
  margin-right: 8px;
}

th .fa-sort {
  color: rgb(222, 226, 230);
}

th .fa-sort:hover,
th .fa-sort-up,
th .fa-sort-down {
  color: rgba(1, 203, 186, 1);
}

.form-invalid-message {
  color: #d42e1c;
  font-size: 13px;
}

.form-valid-message {
  color: #28a745;
  font-size: 13px;
}

.errorMsg {
  color: #d42e1c;
}

.scenario-infos {
  border-top: solid 1px lightgray;
  margin-top: 10px;
  padding-top: 10px;
}

input:disabled {
  cursor: not-allowed;
}

/*  Modal */
mat-dialog-container#modal-component {
  border: 3px solid black;
  border-radius: 11px;
  background-color: white;
}

#open-project-modal {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(1fr, 3);
}

.modal-title {
  font-size: 22px;
}

.modal-footer-center {
  justify-items: center;
}

.table thead th {
  font-weight: 700;
  font-size: 17px;
  line-height: 17px;
  border-top: none;
  padding-bottom: 15px;
  white-space: nowrap;
}

/* SIDEBAR STYLE */

.sidebar {
  background-color: rgba(0, 68, 64, 1);
  color: white;
  position: sticky;
  z-index: 1000;
  height: 100vh;
}

#sidebarCollapsible,
#sidebarAssessCollapsible {
  width: 300px;
  position: fixed;
  top: 0;
  left: -300px;
  transition: all 0.3s;
}

#sidebarCollapsible.active,
#sidebarAssessCollapsible.active {
  left: 0;
}

#overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

#overlay.active {
  display: block;
  opacity: 1;
}

@media screen and (max-width: 991px) {
  #openCollapsibleSidebar {
    display: inline !important;
  }

  #sidebar,
  #sidebarAssess {
    display: none;
  }
}

#openCollapsibleSidebar {
  display: none;
  position: absolute;
  left: 24px;
  top: 17px;
  background-color: transparent;
  border: 0;
  font-size: 38px;
  color: white;
}

#openCollapsibleSidebar:hover {
  color: #a3f0dd;
}

/* END SIDEBAR STYLE */

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.gray {
  color: #949a9c;
}

.warning {
  color: #af9235;
}

.select-default {
  display: none;
}

th[sortable].desc:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

th[sortable].asc:before,
th[sortable].desc:before {
  font-family: "Font Awesome 5 Free";
  content: "\f0dd";
  display: block;
  float: left;
  margin-left: -22px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f9f9f9;
}

.link {
  color: rgba(0, 120, 111, 1) !important;
  text-decoration: underline !important;
}

.menu-li-scenario,
.menu-li {
  width: 100%;
}

.menu-li {
  padding-right: 2px;
}

.scenario-name-container {
  width: 100%;
  display: inline-flex;
}

.scenario-name {
  width: 100%;
  display: inherit;
}
.scenario-header-name,
.component-name-sidebar {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 87%;
  display: block;
  overflow: hidden;
}

.component-name-sidebar {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
}

.scenario-title > em,
.label-with-plus > em,
.component-name-container > .component-actions {
  margin-left: 5px;
  color: rgba(1, 203, 186, 1);
}

.scenario-name em,
.label-with-plus em,
.component-name-container > .component-actions {
  color: rgba(1, 203, 186, 1);
  margin-left: 5px;
  float: right;
  padding-top: 4px;
}

.componentselected + .component-actions {
  color: rgba(0, 68, 64, 1);
}

.component-name-container {
  width: 90%;
  display: inline-flex;
}

.space-left {
  padding-left: 10px;
}

.sub-list-scenario-definition > li > div.label-with-plus {
  padding-top: 3px;
  padding-bottom: 3px;
  text-decoration: none;
  text-transform: capitalize;
}

.terms-of-use-modal .modal-dialog {
  max-width: 70%;
}

.project-container {
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}

.project-edit {
  font-size: 29px;
  font-weight: 500;
}

.project-title,
.back {
  display: inline;
}

.project-type {
  color: gray;
  padding-top: 10px;
}

.project-name {
  padding-right: 10px;
}

.back-btn {
  font-size: 18px;
  vertical-align: top;
  margin-top: 2px;
  margin-right: 20px;
}

.side-bar-content {
  margin-top: 20px;
  display: block;
}

.general-info-block {
  margin-top: 20px;
}

.project-main {
  padding-left: 11px !important;
  padding-right: 0px;
  background-color: #f5f5f5;
}

.pointer {
  cursor: pointer;
}

.clickable:hover {
  cursor: pointer;
  color: rgba(1, 203, 186, 1) !important;
  background-color: transparent !important;
}

.modal-xxl {
  max-width: 90% !important;
}
